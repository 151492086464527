import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';

import { scrollToTop } from '../store/actions';
import { getKey } from '../helpers';
import BlogList from '../components/compound/BlogList';

/**
 * Deathmatch Component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const Deathmatch = props => {
  const dispatch = useDispatch();
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  const posts = getKey(props, 'data.allHubspotPost.edges');
  const numberOfBlogPages = getKey(props, 'pageContext.numberOfBlogPages');
  const currentBlogPage = getKey(props, 'pageContext.currentBlogPage');

  dispatch(scrollToTop());

  return (
    <>
      {metaTitle && (
        <Helmet
          title="Alestra - Techcast"
          meta={[
            { content: metaTitle, name: 'description' },
            { content: `${metaTitle}, alestra`, name: 'keywords' },
          ]}
        >
          <script src="https://apis.google.com/js/platform.js" />
          <html lang="es" />
        </Helmet>
      )}
      <BlogList
        key={`deathmatch${currentBlogPage}`}
        posts={posts}
        numberOfBlogPages={numberOfBlogPages}
        currentBlogPage={currentBlogPage}
        pageTitle="Techcast"
        pagination="/informacion/techcast"
        isBitAndBytes
        isTechCast
        section="techcast"
      />
    </>
  );
};

export default Deathmatch;

export const techcastQuery = graphql`
  query techcastQuery($skip: Int!, $limit: Int!) {
    allHubspotPost(
      sort: { fields: published, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        topics: {
          elemMatch: {
            id: {
              eq: 51758783852
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          slug
          title
          body
          published_format(formatString: "D/M/YYYY")
          feature_image {
            alt_text
            url
          }
        }
      }
    }
  }
`;
